import React from 'react';
import { FONT_BLACK } from '../../../../../colors';

const LegendItem = ({ color, label, width = 15, height = 15 }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
      <div
        style={{
          width: `${width}px`,
          height: `${height}px`,
          backgroundColor: color,
          marginRight: '8px',
        }}
      />
      <span style={{ fontSize: '16px', color: FONT_BLACK }}>{label}</span>
    </div>
  );
};

export default LegendItem;
