import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_ROOT_URL, KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_GUIDED_SETUP_BASE = `${KA_API_URL}/guidedsetup`;
const SETUP_COMPLETION = `${KA_API_ROOT_URL}/auth/setup_completion`;

/**
 * Requests
 */

const fetchGuidedSetup = async () => axios.get(GET_GUIDED_SETUP_BASE);
const fetchSetupCompletion = async () => axios.get(SETUP_COMPLETION);

/**
 * Hooks
 */

export const GUIDED_SETUP_QUERY_KEY = ['guidedSetup'];
const SETUP_COMPLETION_QUERY_KEY = ['guidedSetup'];

export const useGuidedSetup = queryConfig =>
  useQuery(
    GUIDED_SETUP_QUERY_KEY,
    async () => {
      const response = await fetchGuidedSetup();

      return response.data;
    },
    queryConfig,
  );

export const useSetupCompletion = queryConfig =>
  useQuery(
    SETUP_COMPLETION_QUERY_KEY,
    async () => {
      const response = await fetchSetupCompletion();

      return response.data;
    },
    queryConfig,
  );
