import _, { uniqueId } from 'lodash';
import React from 'react';
import camelcase from 'camelcase';
import { DEMAND_METRIC, INCOME_METRIC } from './atAGlanceTabConstants';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import { getDimColumnKey } from './revenueUpliftDashboardComputations';
import {
  convertColumnNameToFrequency,
  convertFrequencyToColumnName,
  isPriceFrequencyColumnName,
  PRICE_FREQ_COL_TO_HEADER,
} from './priceChangeFrequencyUtil';
import { BUCKETS, BUCKET_TO_LABEL, PRICE_CHANGE_BUCKET_ID } from './pricingTabConstants';
import { DAYPART_DIMENSION_TYPE, HOUR_DIMENSION_TYPE, PriceChangeFrequency } from '../../../workflow/workflowConstants';
import { DATE_RANGE_SELECTION } from '../../../../utils/date-handling';

/**
 * These are special-case derived dimensions that we need to display in the price rec table if they're part of the
 * workflow.
 */
const SPECIAL_DERIVED_DIM_TYPES = new Set([HOUR_DIMENSION_TYPE, DAYPART_DIMENSION_TYPE]);

// Create an object keyed by the product dimension id so that we can identify unique products per frequency
// and to later determine what product dimension name to display in the column header on the price rec table
export const usePriceRecDims = () => {
  const { dimensions } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const renderableDims = dimensions.filter(
      dim => dim.derived === false || SPECIAL_DERIVED_DIM_TYPES.has(dim.dimension_type),
    );

    return _.keyBy(renderableDims, 'product_dimension_id');
  }, [dimensions]);
};

export const usePricingScenariosData = () => {
  const { dataPointsByDate, priceDiffPctChange, stlyDemand, stlyIncome } = React.useContext(DashboardContext);

  const data = React.useMemo(() => {
    const baselineRevenue = _.sumBy(dataPointsByDate, 'baselineRevenue');
    const newRevenue = _.sumBy(dataPointsByDate, 'newRevenue');

    const baselineDemand = _.sumBy(dataPointsByDate, 'baselineDemand');
    const newDemand = _.sumBy(dataPointsByDate, 'newDemand');

    const pricePct = priceDiffPctChange * 100;
    const incomePct = ((newRevenue - baselineRevenue) / baselineRevenue) * 100;
    const demandPct = ((newDemand - baselineDemand) / baselineDemand) * 100;

    const incomeMetricCompareValues = [
      {
        key: 'new_prices',
        value: newRevenue,
      },
      {
        key: 'baseline',
        value: baselineRevenue,
      },
    ];
    const demandMetricCompareValues = [
      {
        key: 'new_prices',
        value: newDemand,
      },
      {
        key: 'baseline',
        value: baselineDemand,
      },
    ];

    if (stlyIncome) {
      incomeMetricCompareValues.push({
        key: 'stly',
        value: stlyIncome,
      });
    }

    if (stlyDemand) {
      demandMetricCompareValues.push({
        key: 'stly',
        value: stlyDemand,
      });
    }

    return {
      pricePct,
      incomePct,
      demandPct,
      compareData: [
        {
          metric: INCOME_METRIC,
          values: incomeMetricCompareValues,
        },
        {
          metric: DEMAND_METRIC,
          values: demandMetricCompareValues,
        },
      ],
    };
  }, [dataPointsByDate, priceDiffPctChange, stlyDemand, stlyIncome]);

  return data;
};

const productDimensionColumnNameToId = columnName => {
  return columnName.replace('productDim', '').replace('Id', '');
};

const getDatasetStlyKey = (isPriceOptimizationProfit, metric, range) => {
  const parseRange = () => {
    switch (range) {
      case DATE_RANGE_SELECTION.NEXT_7_DAYS:
        return 'next_7';
      case DATE_RANGE_SELECTION.NEXT_30_DAYS:
        return 'next_30';
      case DATE_RANGE_SELECTION.NEXT_90_DAYS:
        return 'next_90';
      case DATE_RANGE_SELECTION.NEXT_365_DAYS:
        return 'next_365';
      case DATE_RANGE_SELECTION.CURRENT_MONTH:
        return 'current_month';
      case DATE_RANGE_SELECTION.CURRENT_QUARTER:
        return 'current_quarter';
      case DATE_RANGE_SELECTION.CURRENT_YEAR:
        return 'current_year';
      case DATE_RANGE_SELECTION.NEXT_MONTH:
        return 'month_next';
      case DATE_RANGE_SELECTION.NEXT_QUARTER:
        return 'quarter_next';
      case DATE_RANGE_SELECTION.NEXT_YEAR:
        return 'year_next';
      default:
        return range;
    }
  };

  const parsedRange = parseRange();

  if (metric === DEMAND_METRIC) {
    return camelcase(`stly-${parsedRange}-demand`);
  }

  if (metric === INCOME_METRIC) {
    return isPriceOptimizationProfit
      ? camelcase(`stly-${parsedRange}-profit`)
      : camelcase(`stly-${parsedRange}-revenue`);
  }

  return '';
};

const getDatasetKeys = (isPriceOptimizationProfit, metric, range) => {
  if (metric === DEMAND_METRIC) {
    return [camelcase(`baseline-${range}-demand`), camelcase(`new-${range}-demand`)];
  }

  if (metric === INCOME_METRIC) {
    return isPriceOptimizationProfit
      ? [camelcase(`baseline-${range}-profit`), camelcase(`new-${range}-profit`)]
      : [camelcase(`baseline-${range}-revenue`), camelcase(`new-${range}-revenue`)];
  }

  return ['', ''];
};

const isProductDimColumnName = columnName => columnName.startsWith('productDim');

const FILTER_FREQUENCIES = [PriceChangeFrequency.MONTH, PriceChangeFrequency.QUARTER, PriceChangeFrequency.YEAR];

export const DISPLAY_FREQUENCIES = [
  PriceChangeFrequency.DOW,
  PriceChangeFrequency.WEEKPART,
  PriceChangeFrequency.SEASON,
];

const DISPLAY_FREQUENCY_TO_LABEL = {
  [PriceChangeFrequency.DOW]: 'Day of Week',
  [PriceChangeFrequency.WEEKPART]: 'Week Part',
  [PriceChangeFrequency.SEASON]: 'Season',
};

export const getFrequencyLabel = frequency => DISPLAY_FREQUENCY_TO_LABEL[frequency] ?? '';

const getRowValueByFrequency = (row, frequency) => {
  if (frequency === PriceChangeFrequency.DAY) {
    return `${row[convertFrequencyToColumnName(PriceChangeFrequency.DAY)]}`;
  }

  if (frequency === PriceChangeFrequency.MONTH) {
    return `${row[convertFrequencyToColumnName(PriceChangeFrequency.MONTH)]} ${
      row[convertFrequencyToColumnName(PriceChangeFrequency.YEAR)]
    }`;
  }

  if (frequency === PriceChangeFrequency.QUARTER) {
    return `${row[convertFrequencyToColumnName(PriceChangeFrequency.QUARTER)]} ${
      row[convertFrequencyToColumnName(PriceChangeFrequency.YEAR)]
    }`;
  }

  if (frequency === PriceChangeFrequency.YEAR) {
    return `${row[convertFrequencyToColumnName(PriceChangeFrequency.YEAR)]}`;
  }

  return null;
};

const shouldDisplayPriceChangeFreq = (priceChangeFreq, priceFrequencyDetails) => {
  return DISPLAY_FREQUENCIES.includes(priceChangeFreq) && priceFrequencyDetails.includes(priceChangeFreq);
};

// Hook for the 'Pricing' tab recommendations table
export const usePriceRecTableData = (approvedPricesApi, selectedLocationId, frequency, selectedFrequencyValue) => {
  const { dimensionValues, locations, isPriceOptimizationProfit, priceFrequencyDetails } = React.useContext(
    DomainContext,
  );
  const { pricingRows: rows, selectedRange, shouldAggregateDimensions, pricingDimIds } = React.useContext(
    DashboardContext,
  );

  const columns = React.useMemo(() => {
    const productDimCols = [];
    const priceFrequencyCols = [];
    const { productId, locationId, ...rest } = rows[0] ?? {};

    Object.keys(rest).forEach(columnName => {
      const priceChangeFreq = convertColumnNameToFrequency(columnName);

      if (shouldDisplayPriceChangeFreq(priceChangeFreq, priceFrequencyDetails)) {
        priceFrequencyCols.push(columnName);
      } else if (
        isProductDimColumnName(columnName) &&
        (!shouldAggregateDimensions || pricingDimIds.includes(productDimensionColumnNameToId(columnName)))
      ) {
        productDimCols.push(productDimensionColumnNameToId(columnName));
      }
    });

    return { productDimCols, priceFrequencyCols };
  }, [rows, shouldAggregateDimensions, pricingDimIds, priceFrequencyDetails]);

  const priceRecData = React.useMemo(() => {
    const locationsById = _.keyBy(locations, 'location_id');
    const productDimValuesById = _.keyBy(dimensionValues, 'id');
    const getLocationDescription = locationId => locationsById[locationId]?.location_description;

    const parsedRows = {};
    rows.forEach((row, i) => {
      const dimensionColumns = {};
      const { productId, locationId, ...rest } = row;

      if (locationId !== selectedLocationId) {
        return;
      }

      if (FILTER_FREQUENCIES.includes(frequency) && getRowValueByFrequency(row, frequency) !== selectedFrequencyValue) {
        return;
      }

      Object.entries(rest).forEach(([columnName, value]) => {
        if (
          isPriceFrequencyColumnName(columnName) &&
          priceFrequencyDetails.includes(convertColumnNameToFrequency(columnName))
        ) {
          dimensionColumns[columnName] = value;
        } else if (
          isProductDimColumnName(columnName) &&
          (!shouldAggregateDimensions || pricingDimIds.includes(productDimensionColumnNameToId(columnName)))
        ) {
          dimensionColumns[productDimensionColumnNameToId(columnName)] = productDimValuesById[value]?.value;
        }
      });

      const [baselineDemandKey] = getDatasetKeys(isPriceOptimizationProfit, DEMAND_METRIC, selectedRange);
      const [baselineIncomeKey] = getDatasetKeys(isPriceOptimizationProfit, INCOME_METRIC, selectedRange);
      const { baselinePrice, newPrice, [baselineIncomeKey]: currentIncome, [baselineDemandKey]: currentDemand } = row;

      const {
        price: approvedPrice,
        demand: approvedDemand,
        revenue: approvedRevenue,
        uplift: approvedUplift,
      } = approvedPricesApi.selectors.getApprovedPricePoint(row);

      const key = [locationId, ...Object.values(dimensionColumns)].join('-');
      const existingRow = parsedRows[key];

      if (existingRow) {
        existingRow.uplift += approvedUplift;
        existingRow.demandChange += approvedDemand - currentDemand;
        existingRow.newIncome += approvedRevenue;
        existingRow.currentIncome += currentIncome;
        existingRow.newDemand += approvedDemand;
        existingRow.currentDemand += currentDemand;
      } else {
        parsedRows[key] = {
          rowId: i,
          productId,
          locationDescription: getLocationDescription(locationId),
          baselinePrice,
          newPrice,
          approvedPrice,
          priceDifferencePct: baselinePrice > 0 ? ((approvedPrice - baselinePrice) / baselinePrice) * 100 : null,
          uplift: approvedUplift,
          demandChange: approvedDemand - currentDemand,
          newIncome: approvedRevenue,
          currentIncome,
          newDemand: approvedDemand,
          currentDemand,
          ...dimensionColumns,
        };
      }
    });

    return _.orderBy(Object.values(parsedRows), 'uplift', ['desc']);
  }, [
    rows,
    locations,
    dimensionValues,
    selectedRange,
    isPriceOptimizationProfit,
    selectedLocationId,
    frequency,
    selectedFrequencyValue,
    pricingDimIds,
    shouldAggregateDimensions,
    approvedPricesApi.selectors,
    priceFrequencyDetails,
  ]);

  return { priceRecData, ...columns };
};

// Hook for the 'At a Glance' tab recommendations table
export const useTopPricingRecTableData = maxRows => {
  const { dimensionValues, locations } = React.useContext(DomainContext);
  const { pricingRecommendationRows } = React.useContext(DashboardContext);

  const columns = React.useMemo(() => {
    const productDimCols = [];
    const priceFrequencyCols = [];
    const { productId, locationId, currentPrice, kaizenPrice, uplift, ...rest } = pricingRecommendationRows[0] ?? {};

    Object.keys(rest).forEach(columnName => {
      if (isPriceFrequencyColumnName(columnName)) {
        priceFrequencyCols.push(columnName);
      } else if (isProductDimColumnName(columnName)) {
        productDimCols.push(productDimensionColumnNameToId(columnName));
      }
    });

    return { productDimCols, priceFrequencyCols };
  }, [pricingRecommendationRows]);

  const priceRecData = React.useMemo(() => {
    const locationsById = _.keyBy(locations, 'location_id');
    const productDimValuesById = _.keyBy(dimensionValues, 'id');
    const getLocationDescription = locationId => locationsById[locationId]?.location_description;

    const parsedRows = pricingRecommendationRows.map(row => {
      const dimensionColumns = {};
      const { productId, locationId, currentPrice, kaizenPrice, uplift, ...rest } = row;

      Object.entries(rest).forEach(([columnName, value]) => {
        if (isPriceFrequencyColumnName(columnName)) {
          dimensionColumns[columnName] = value;
        } else {
          dimensionColumns[productDimensionColumnNameToId(columnName)] = productDimValuesById[value]?.value;
        }
      });

      return {
        productId,
        locationDescription: getLocationDescription(locationId),
        currentPrice,
        kaizenPrice,
        uplift,
        ...dimensionColumns,
      };
    });

    return _.orderBy(parsedRows, 'uplift', ['desc']).slice(0, maxRows);
  }, [pricingRecommendationRows, locations, dimensionValues, maxRows]);

  return { priceRecData, ...columns };
};

export const useSummaryData = () => {
  const { isPriceOptimizationProfit } = React.useContext(DomainContext);
  const { pricingRows, pricingHistory, selectedRange } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const [baselineDemandKey, newDemandKey] = getDatasetKeys(isPriceOptimizationProfit, DEMAND_METRIC, selectedRange);
    const stlyDemandKey = getDatasetStlyKey(isPriceOptimizationProfit, DEMAND_METRIC, selectedRange);
    const [baselineIncomeKey, newIncomeKey] = getDatasetKeys(isPriceOptimizationProfit, INCOME_METRIC, selectedRange);
    const stlyIncomeKey = getDatasetStlyKey(isPriceOptimizationProfit, INCOME_METRIC, selectedRange);

    const baselineIncome = _.sumBy(pricingRows, baselineIncomeKey);
    const newIncome = _.sumBy(pricingRows, newIncomeKey);
    const stlyIncome = _.sumBy(pricingHistory, stlyIncomeKey);
    const baselineDemand = _.sumBy(pricingRows, baselineDemandKey);
    const newDemand = _.sumBy(pricingRows, newDemandKey);
    const stlyDemand = _.sumBy(pricingHistory, stlyDemandKey);

    const incomeMetricCompareValues = [
      {
        key: 'baseline',
        value: baselineIncome,
      },
      {
        key: 'new_prices',
        value: newIncome,
      },
    ];
    const demandMetricCompareValues = [
      {
        key: 'baseline',
        value: baselineDemand,
      },
      {
        key: 'new_prices',
        value: newDemand,
      },
    ];

    if (stlyIncome > 0) {
      incomeMetricCompareValues.push({
        key: 'stly',
        value: stlyIncome,
      });
    }

    if (stlyDemand > 0) {
      demandMetricCompareValues.push({
        key: 'stly',
        value: stlyDemand,
      });
    }

    return [
      {
        metric: INCOME_METRIC,
        values: incomeMetricCompareValues,
      },
      {
        metric: DEMAND_METRIC,
        values: demandMetricCompareValues,
      },
    ];
  }, [pricingRows, pricingHistory, selectedRange, isPriceOptimizationProfit]);
};

export const useBreakdownData = (selectedDim, selectedMetric) => {
  const { isPriceOptimizationProfit } = React.useContext(DomainContext);
  const { pricingRows, getDimValueId, getDimValueLabel, selectedRange } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const selectedDimId = selectedDim.product_dimension_id;
    const rowsByDimValueId = _.groupBy(pricingRows, getDimColumnKey(selectedDimId));

    const data = Object.entries(rowsByDimValueId).map(([dimValueId, rows]) => {
      const id = getDimValueId(selectedDimId, dimValueId) ?? uniqueId();
      const name = getDimValueLabel(selectedDimId, dimValueId) ?? id ?? 'Unknown';

      const [currentMetricKey, newMetricKey] = getDatasetKeys(isPriceOptimizationProfit, selectedMetric, selectedRange);
      const current = _.sumBy(rows, currentMetricKey) ?? 0;
      const newMetric = _.sumBy(rows, newMetricKey) ?? 0;

      return {
        id,
        name,
        diff: newMetric - current,
      };
    });

    // Take the 10 items with the highest absolute value
    if (data.length > 10) {
      data.sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));
      return data.slice(0, 10).sort((a, b) => b.diff - a.diff);
    }

    return data.sort((a, b) => b.diff - a.diff);
  }, [
    pricingRows,
    selectedDim,
    selectedMetric,
    getDimValueId,
    getDimValueLabel,
    isPriceOptimizationProfit,
    selectedRange,
  ]);
};

export const usePriceChangeData = selectedDim => {
  const { pricingRows, getDimValueId, getDimValueLabel } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const selectedDimId = selectedDim.product_dimension_id;
    const rowsByDimValueId = _.groupBy(pricingRows, getDimColumnKey(selectedDimId));

    const data = Object.entries(rowsByDimValueId).map(([dimValueId, rows]) => {
      const id = getDimValueId(selectedDimId, dimValueId) ?? uniqueId();
      const name = getDimValueLabel(selectedDimId, dimValueId) ?? id ?? 'Unknown';

      const currentPrices = _.sumBy(rows, 'baselinePrice') ?? 0;
      const newPrices = _.sumBy(rows, 'newPrice') ?? 0;

      return {
        id,
        name,
        diff: currentPrices > 0 ? ((newPrices - currentPrices) / currentPrices) * 100 : 0,
      };
    });

    // Take the 10 items with the highest absolute value
    if (data.length > 10) {
      data.sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));
      return data.slice(0, 10).sort((a, b) => b.diff - a.diff);
    }

    return data.sort((a, b) => b.diff - a.diff);
  }, [pricingRows, selectedDim, getDimValueId, getDimValueLabel]);
};

export const usePriceChangeDistributionData = () => {
  const { pricingRows } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const rowsPerBucket = {};
    BUCKETS.forEach(bucket => {
      rowsPerBucket[bucket] = 0;
    });

    pricingRows.forEach(row => {
      const { [PRICE_CHANGE_BUCKET_ID]: rowBucket } = row;
      rowsPerBucket[rowBucket] += 1;
    });

    const total = Object.values(rowsPerBucket).reduce((acc, bucket) => acc + bucket, 0);

    return BUCKETS.map(bucket => {
      return {
        key: bucket,
        label: BUCKET_TO_LABEL[bucket],
        value: total > 0 ? (rowsPerBucket[bucket] / total) * 100 : 0,
      };
    });
  }, [pricingRows]);
};

export const useExportData = (
  approvedPricesApi,
  selectedLocationId,
  frequency = null,
  selectedFrequencyValue = null,
  useFilteredRows = false,
) => {
  const { dimensionValues, locations, dimensions, priceFrequencyDetails } = React.useContext(DomainContext);
  const {
    pricingRows,
    unfilteredPricingRows,
    baselineComparison,
    shouldAggregateDimensions,
    pricingDimIds,
  } = React.useContext(DashboardContext);
  const { newPrices } = baselineComparison;

  const rows = useFilteredRows ? pricingRows : unfilteredPricingRows;

  const [keys, headerLabels] = React.useMemo(() => {
    const productDimCols = {};
    const priceFrequencyCols = {};
    const { productId, locationId, ...rest } = rows[0] ?? {};

    Object.keys(rest).forEach(columnName => {
      if (priceFrequencyDetails.includes(convertColumnNameToFrequency(columnName))) {
        priceFrequencyCols[columnName] = PRICE_FREQ_COL_TO_HEADER[columnName];
      } else if (
        isProductDimColumnName(columnName) &&
        (!shouldAggregateDimensions || pricingDimIds.includes(productDimensionColumnNameToId(columnName)))
      ) {
        productDimCols[columnName] = dimensions.find(
          dim => `${dim.product_dimension_id}` === `${productDimensionColumnNameToId(columnName)}`,
        )?.name;
      }
    });

    return [
      ['locationDescription', ...Object.keys(productDimCols), ...Object.keys(priceFrequencyCols), 'newPrice'],
      {
        locationDescription: 'Location',
        ...productDimCols,
        ...priceFrequencyCols,
        newPrice: newPrices.label,
      },
    ];
  }, [rows, dimensions, newPrices.label, priceFrequencyDetails, shouldAggregateDimensions, pricingDimIds]);

  const exportData = React.useMemo(() => {
    const locationsById = _.keyBy(locations, 'location_id');
    const productDimValuesById = _.keyBy(dimensionValues, 'id');
    const getLocationDescription = locationId => locationsById[locationId]?.location_description;

    let filteredRows = useFilteredRows ? rows.filter(row => row.locationId === selectedLocationId) : rows;

    if (FILTER_FREQUENCIES.includes(frequency) && selectedFrequencyValue && useFilteredRows) {
      filteredRows = filteredRows.filter(row => getRowValueByFrequency(row, frequency) === selectedFrequencyValue);
    }

    const parsedRows = {};
    filteredRows.forEach(row => {
      const dimensionColumns = {};
      const { productId, locationId, ...rest } = row;

      Object.entries(rest).forEach(([columnName, value]) => {
        if (priceFrequencyDetails.includes(convertColumnNameToFrequency(columnName))) {
          dimensionColumns[columnName] = value;
        } else if (
          isProductDimColumnName(columnName) &&
          (!shouldAggregateDimensions || pricingDimIds.includes(productDimensionColumnNameToId(columnName)))
        ) {
          dimensionColumns[columnName] = productDimValuesById[value]?.value;
        }
      });

      const { newPrice } = row;
      const { uplift: approvedUplift } = approvedPricesApi.selectors.getApprovedPricePoint(row);

      const key = [locationId, ...Object.values(dimensionColumns)].join('-');
      const existingRow = parsedRows[key];

      if (existingRow) {
        existingRow.uplift += approvedUplift;
      } else {
        parsedRows[key] = {
          locationDescription: getLocationDescription(locationId),
          newPrice,
          uplift: approvedUplift,
          ...dimensionColumns,
        };
      }
    });

    return _.orderBy(Object.values(parsedRows), 'uplift', ['desc']);
  }, [
    locations,
    dimensionValues,
    useFilteredRows,
    rows,
    frequency,
    selectedFrequencyValue,
    selectedLocationId,
    approvedPricesApi.selectors,
    priceFrequencyDetails,
    shouldAggregateDimensions,
    pricingDimIds,
  ]);

  return { keys, exportData, headerLabels };
};
