/**
 * Developer Note: These colors should be kept in sync with colors.css
 */

/**
 * Official, globally available CSS color variables
 */

/* Primary colors */
export const LIGHT_GREEN = '#64f884';
export const LIGHT_GREEN_HOVER = '#84fc9e';
export const LIGHT_GREEN_SHADOW = '#76f792';
export const MEDIUM_LIGHT_GREEN = '#4bcf6a';
export const MID_GREEN = '#0f4145';
export const DARK_GREEN = '#072e31';

/* Secondary colors */
export const LIGHT_BLUE = '#0d729e';
export const LIGHT_COOL_GRAY = '#b1b3b3';
export const MEDIUM_COOL_GRAY = '#63666a';

/* Primary font colors */
export const FONT_BLACK = '#2e2e2e';
export const FONT_WHITE = '#ffffff';
export const FONT_GRAY = '#797979';
export const LONG_FORM_FONT_GRAY = '#585858';

/* Background colors */
export const BACKGROUND_GRAY = '#eaf7fd';

/* Semantic colors */
export const SUCCESS_GREEN = '#5fad56';
export const ERROR = '#b3270f';

/* Others... */
export const SIMPLE_GRAY = '#888888';
export const LOW_TEMPERATURE_OVERLAY_BASE = '#91BBFF88';
export const HIGH_TEMPERATURE_OVERLAY_BASE = '#FFD9D9BB';

/**
 * Unofficial colors found in designs but not part of the official ProfitRover Design Guide
 */

/* Background colors */
export const LIGHTER_BACKGROUND_GRAY = '#ececec';

/* Primary font colors */
export const DARKER_FONT_BLACK = '#3e3e3e';
