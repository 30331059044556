export const NEXT_7_PERIOD = 'next_7';
export const NEXT_30_PERIOD = 'next_30';
export const NEXT_90_PERIOD = 'next_90';
export const NEXT_365_PERIOD = 'next_365';
export const THIS_CALENDAR_MONTH_PERIOD = 'current_month';
export const THIS_CALENDAR_QUARTER_PERIOD = 'current_quarter';
export const THIS_CALENDAR_YEAR_PERIOD = 'current_year';
export const NEXT_CALENDAR_MONTH_PERIOD = 'next_month';
export const NEXT_CALENDAR_QUARTER_PERIOD = 'next_quarter';
export const NEXT_CALENDAR_YEAR_PERIOD = 'next_year';

export const PERIOD_TO_LABEL = {
  [NEXT_7_PERIOD]: 'Next 7 Days',
  [NEXT_30_PERIOD]: 'Next 30 Days',
  [NEXT_90_PERIOD]: 'Next 90 Days',
  [NEXT_365_PERIOD]: 'Next 365 Days',
  [THIS_CALENDAR_MONTH_PERIOD]: 'This Calendar Month',
  [THIS_CALENDAR_QUARTER_PERIOD]: 'This Calendar Quarter',
  [THIS_CALENDAR_YEAR_PERIOD]: 'This Calendar Year',
  [NEXT_CALENDAR_MONTH_PERIOD]: 'Next Calendar Month',
  [NEXT_CALENDAR_QUARTER_PERIOD]: 'Next Calendar Quarter',
  [NEXT_CALENDAR_YEAR_PERIOD]: 'Next Calendar Year',
};

export const HOURS_OF_OPERATION_PERIOD_OPTIONS = [
  NEXT_7_PERIOD,
  NEXT_30_PERIOD,
  NEXT_90_PERIOD,
  NEXT_365_PERIOD,
  THIS_CALENDAR_MONTH_PERIOD,
  THIS_CALENDAR_QUARTER_PERIOD,
  THIS_CALENDAR_YEAR_PERIOD,
  NEXT_CALENDAR_MONTH_PERIOD,
  NEXT_CALENDAR_QUARTER_PERIOD,
  NEXT_CALENDAR_YEAR_PERIOD,
].map(value => ({ value, label: PERIOD_TO_LABEL[value] }));
