import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { useAllCustomers } from '../../data-access/query/users';

const mapCustomerToOption = customer => ({
  label: `${customer.overview.name} (${customer.customer_id})`,
  value: customer.customer_id,
});

const CustomerSelect = props => {
  const { data: allCustomers = [], isLoading } = useAllCustomers();
  const options = React.useMemo(
    () => _.sortBy(allCustomers.map(mapCustomerToOption), option => option.label.toLowerCase()),
    [allCustomers],
  );

  return (
    <Select
      {...props}
      options={options}
      getOptionValue={option => option.label}
      isLoading={isLoading}
      isSearchable
      placeholder="Select a customer..."
    />
  );
};

export default CustomerSelect;
