import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const updateInventorySettings = async inventorySettings =>
  axios.put(`${KA_API_URL}/config/inventory_settings`, inventorySettings);

/**
 * Hooks
 */

export const useUpdateInventorySettings = mutationConfig =>
  useMutation(async inventorySettings => {
    const response = await updateInventorySettings(inventorySettings);

    return response?.data ?? {};
  }, mutationConfig);
