import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import HoursOfOperationChart from './charts/HoursOfOperationChart';
import { HoursToolbar } from './FiltersToolbar';
import {
  BASE_RECOMMENDATIONS,
  CURRENT_HOURS_COMPARISON,
  HOURS_COMPARISON_OPTIONS,
  HOURS_OF_OPERATION_SETTINGS_KEYS,
} from './hoursOfOperationConstants';
import HoursTabContext from './hoursTabContext';
import { DailyHoursAndRevenueTable, GraphSourcesTable } from './HoursTabTable';
import {
  hoursOfOperationToBarOffsetAndWidth,
  useAverageHourlyRevenueDataForDay,
  useLocationHoursOfOperation,
} from './hoursTabUtil';
import { HOURS_OF_OPERATION_PERIOD_OPTIONS, NEXT_90_PERIOD, PERIOD_TO_LABEL } from './periodConstants';
import { DashboardContext } from './revenueUpliftContexts';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import { formatNumber } from './staffingTabUtil';
import { DAYS_OF_WEEK } from '../../../accountSettings/locationSettings/locationManagementConstants';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { gaEmitDayOfWeekSelectClick } from '../../../../google-analytics/hoursTab';
import './hours-tab.scss';

const DaysSection = ({ isActive, selectedRecommendationApi }) => {
  const { locationsApi } = React.useContext(DashboardContext);
  const { selectedLocation } = locationsApi;
  const { selectedPeriod } = React.useContext(HoursTabContext);

  return (
    <div className="section days-section">
      <div className="header">
        <h2>Hours Of Operation</h2>
        <h3>
          {selectedLocation.label} ({selectedPeriod.label})
        </h3>
      </div>
      <DailyHoursAndRevenueTable isActive={isActive} selectedRecommendationApi={selectedRecommendationApi} />
    </div>
  );
};

const hourTickWidth = 85.7;

const DetailsSection = ({ selectedRecommendationApi, selectedAverageHourlyRevenueData }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const {
    selectedPeriod,
    selectedDow,
    setSelectedDow,
    dowOptions,
    selectedComparison,
    selectNextDow,
    selectPreviousDow,
  } = React.useContext(HoursTabContext);

  const { locationHoursOfOperation } = selectedRecommendationApi;
  const { chartData, offset, hours, maxRevenue } = selectedAverageHourlyRevenueData;

  const numberFormatter = value => formatNumber(value, { currencySymbol });

  const dayHoursOfOperation = React.useMemo(
    () => ({
      newHours: (locationHoursOfOperation?.newHours ?? []).find(({ day }) => day === selectedDow.value) ?? {},
      current: (locationHoursOfOperation?.current ?? []).find(({ day }) => day === selectedDow.value) ?? {},
      recommended: (locationHoursOfOperation?.recommended ?? []).find(({ day }) => day === selectedDow.value) ?? {},
    }),
    [selectedDow, locationHoursOfOperation],
  );

  const newBar = hoursOfOperationToBarOffsetAndWidth(dayHoursOfOperation.newHours, hours, offset, hourTickWidth);
  const currentBar = hoursOfOperationToBarOffsetAndWidth(dayHoursOfOperation.current, hours, offset, hourTickWidth);
  const recommendedBar = hoursOfOperationToBarOffsetAndWidth(
    dayHoursOfOperation.recommended,
    hours,
    offset,
    hourTickWidth,
  );

  const hourFormatter = x => {
    return hours[offset + x] ?? '';
  };

  const onDayOfWeekSelect = dow => {
    gaEmitDayOfWeekSelectClick();
    setSelectedDow(dow);
  };

  const onSelectPreviousDow = () => {
    gaEmitDayOfWeekSelectClick();
    selectPreviousDow();
  };

  const onSelectNextDow = () => {
    gaEmitDayOfWeekSelectClick();
    selectNextDow();
  };

  return (
    <div className="section details-section">
      <div className="header">
        <div className="d-flex align-items-center" style={{ gap: 10 }}>
          <h2>Details for: </h2>
          <div className="selector-container" style={{ flexGrow: 1, minWidth: 150, maxWidth: 150 }}>
            <RevenueUpliftSelect
              value={selectedDow}
              options={dowOptions}
              onChange={onDayOfWeekSelect}
              placeholder="Select Day of Week"
            />
            <button type="button" className="arrow-button left" onClick={onSelectPreviousDow}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <button type="button" className="arrow-button right" onClick={onSelectNextDow}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
        <h3>Avg Revenue Per Hour ({selectedPeriod.label})</h3>
      </div>
      <div className="viz-container">
        <HoursOfOperationChart
          data={chartData}
          numberFormatter={numberFormatter}
          hourFormatter={hourFormatter}
          maxRevenue={maxRevenue}
        />
      </div>
      <div className="hour-ticks mt-2">
        <div className="bar new-hours" style={{ width: newBar.width, left: newBar.offset }} />
        {selectedComparison.value === CURRENT_HOURS_COMPARISON ? (
          <div className="bar current" style={{ width: currentBar.width, left: currentBar.offset }} />
        ) : (
          <div className="bar recommended" style={{ width: recommendedBar.width, left: recommendedBar.offset }} />
        )}
      </div>
      <div className="legend">
        <div className="bars-graph-legend">
          <div className="item">New Hours</div>
          <div className="item">
            {selectedComparison.value === CURRENT_HOURS_COMPARISON ? 'Current Hours' : 'RoverRecs'}
          </div>
        </div>
        {chartData.map(({ label, color }) => (
          <div key={label} className="legend-item">
            <div className="color" style={{ backgroundColor: color }} />
            <span>{label}</span>
          </div>
        ))}
      </div>
      <GraphSourcesTable selectedAverageHourlyRevenueData={selectedAverageHourlyRevenueData} />
    </div>
  );
};

const HoursTab = ({ isActive }) => {
  const { hoursOfOperationSettingsApi } = React.useContext(DashboardContext);
  const {
    selectedPeriod,
    setSelectedPeriod,
    periodOptions,
    selectedComparison,
    setSelectedComparison,
    comparisonOptions,
    selectedLocation,
    setSelectedLocation,
    locationOptions,
    averageHourlyRevenueData,
    averageHourlyRevenueDataLast4Weeks,
    averageHourlyRevenueDataSTLY,
    locationHoursOfOperationApi,
    locationHoursOfOperationLast4WeeksApi,
    locationHoursOfOperationSTLYApi,
  } = React.useContext(HoursTabContext);

  const { currentHoursSettings } = hoursOfOperationSettingsApi;
  const [selectedRecommendationApi, setSelectedRecommendationApi] = React.useState(locationHoursOfOperationApi);
  const [selectedAverageHourlyRevenueData, setSelectedAverageHourlyRevenueData] = React.useState(
    averageHourlyRevenueData,
  );

  React.useEffect(() => {
    switch (currentHoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.BASE_RECOMMENDATIONS_ON]) {
      case BASE_RECOMMENDATIONS.FORECAST_AVG:
        setSelectedRecommendationApi(locationHoursOfOperationApi);
        setSelectedAverageHourlyRevenueData(averageHourlyRevenueData);
        break;
      case BASE_RECOMMENDATIONS.LAST_4_WEEKS_AVG:
        setSelectedRecommendationApi(locationHoursOfOperationLast4WeeksApi);
        setSelectedAverageHourlyRevenueData(averageHourlyRevenueDataLast4Weeks);
        break;
      case BASE_RECOMMENDATIONS.STLY_AVG:
        setSelectedRecommendationApi(locationHoursOfOperationSTLYApi);
        setSelectedAverageHourlyRevenueData(averageHourlyRevenueDataSTLY);
        break;
      default:
        setSelectedRecommendationApi(locationHoursOfOperationApi);
        setSelectedAverageHourlyRevenueData(averageHourlyRevenueData);
        break;
    }
  }, [
    currentHoursSettings,
    locationHoursOfOperationApi,
    locationHoursOfOperationLast4WeeksApi,
    locationHoursOfOperationSTLYApi,
    averageHourlyRevenueData,
    averageHourlyRevenueDataLast4Weeks,
    averageHourlyRevenueDataSTLY,
  ]);

  return (
    <div className="hours-tab">
      <div className="my-2">
        <HoursToolbar
          locationOptions={locationOptions}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          periodOptions={periodOptions}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          comparisonOptions={comparisonOptions}
          selectedComparison={selectedComparison}
          setSelectedComparison={setSelectedComparison}
        />
      </div>
      <ProfitRoverCard className="hours-card">
        <div className="hours-card-content">
          <DaysSection isActive={isActive} selectedRecommendationApi={selectedRecommendationApi} />
          <div className="divider" />
          <DetailsSection
            selectedRecommendationApi={selectedRecommendationApi}
            selectedAverageHourlyRevenueData={selectedAverageHourlyRevenueData}
          />
        </div>
      </ProfitRoverCard>
    </div>
  );
};

const HoursTabContextController = ({ children }) => {
  const { locationsApi } = React.useContext(DashboardContext);
  const { selectedLocation, setSelectedLocation, locationOptions } = locationsApi;
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    value: NEXT_90_PERIOD,
    label: PERIOD_TO_LABEL[NEXT_90_PERIOD],
  });
  const [selectedComparison, setSelectedComparison] = React.useState(HOURS_COMPARISON_OPTIONS[0]);
  const [selectedDow, setSelectedDow] = React.useState({ label: DAYS_OF_WEEK[0], value: DAYS_OF_WEEK[0] });

  const selectNextDow = () => {
    const nextIndex = (DAYS_OF_WEEK.indexOf(selectedDow.value) + 1) % DAYS_OF_WEEK.length;
    setSelectedDow({ label: DAYS_OF_WEEK[nextIndex], value: DAYS_OF_WEEK[nextIndex] });
  };

  const selectPreviousDow = () => {
    const currentIndex = DAYS_OF_WEEK.indexOf(selectedDow.value);
    const previousIndex = currentIndex <= 0 ? DAYS_OF_WEEK.length - 1 : currentIndex - 1;
    setSelectedDow({ label: DAYS_OF_WEEK[previousIndex], value: DAYS_OF_WEEK[previousIndex] });
  };

  const locationHoursOfOperationApi = useLocationHoursOfOperation(
    locationOptions,
    selectedLocation,
    selectedPeriod,
    BASE_RECOMMENDATIONS.FORECAST_AVG,
  );
  const locationHoursOfOperationLast4WeeksApi = useLocationHoursOfOperation(
    locationOptions,
    selectedLocation,
    selectedPeriod,
    BASE_RECOMMENDATIONS.LAST_4_WEEKS_AVG,
  );
  const locationHoursOfOperationSTLYApi = useLocationHoursOfOperation(
    locationOptions,
    selectedLocation,
    selectedPeriod,
    BASE_RECOMMENDATIONS.STLY_AVG,
  );

  const averageHourlyRevenueData = useAverageHourlyRevenueDataForDay(
    selectedDow.value,
    selectedLocation.value,
    selectedPeriod.value,
    locationHoursOfOperationApi.locationHoursOfOperation,
  );
  const averageHourlyRevenueDataLast4Weeks = useAverageHourlyRevenueDataForDay(
    selectedDow.value,
    selectedLocation.value,
    selectedPeriod.value,
    locationHoursOfOperationLast4WeeksApi.locationHoursOfOperation,
  );
  const averageHourlyRevenueDataSTLY = useAverageHourlyRevenueDataForDay(
    selectedDow.value,
    selectedLocation.value,
    selectedPeriod.value,
    locationHoursOfOperationSTLYApi.locationHoursOfOperation,
  );

  const contextValue = {
    selectedLocation,
    setSelectedLocation,
    locationOptions,
    selectedPeriod,
    setSelectedPeriod,
    periodOptions: HOURS_OF_OPERATION_PERIOD_OPTIONS,
    selectedComparison,
    setSelectedComparison,
    comparisonOptions: HOURS_COMPARISON_OPTIONS,
    selectedDow,
    setSelectedDow,
    dowOptions: DAYS_OF_WEEK.map(option => ({ label: option, value: option })),
    selectNextDow,
    selectPreviousDow,
    locationHoursOfOperationApi,
    averageHourlyRevenueData,
    locationHoursOfOperationLast4WeeksApi,
    averageHourlyRevenueDataLast4Weeks,
    locationHoursOfOperationSTLYApi,
    averageHourlyRevenueDataSTLY,
  };

  return <HoursTabContext.Provider value={contextValue}>{children}</HoursTabContext.Provider>;
};

const HoursTabContainer = ({ isActive }) => {
  const { hoursOfOperationSettingsApi, isLoadingHours } = React.useContext(DashboardContext);
  const { isLoadingHoursSettings } = hoursOfOperationSettingsApi;

  if (isLoadingHoursSettings || isLoadingHours) {
    return (
      <div className="hours-tab h-100">
        <CenteredProfitRoverSpinner />
      </div>
    );
  }

  return (
    <HoursTabContextController>
      <HoursTab isActive={isActive} />
    </HoursTabContextController>
  );
};

export default HoursTabContainer;
