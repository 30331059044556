import React from 'react';
import * as d3 from 'd3';

/**
 * PieChart component renders a pie chart using D3.js.
 *
 * @param {Object[]} data - The data to be displayed in the pie chart.
 * Each object should have a `name` and `value` property.
 * @param {number} [width=300] - The width of the SVG element.
 * @param {number} [height=300] - The height of the SVG element.
 * @param {string[]} [colors] - An array of colors to be used for the pie segments. Defaults to D3's schemeCategory10.
 * @param {Function} [valueFormatter=d => d] - The function to format the values in the tooltip.
 *
 * @returns {JSX.Element} The rendered pie chart component.
 */

const PieChart = ({
  data,
  width = 300,
  height = 300,
  colors,
  valueFormatter = d => d, // Default formatter that returns the value unchanged
}) => {
  const svgRef = React.useRef();

  React.useLayoutEffect(() => {
    const radius = Math.min(width, height) / 2;
    const colorScale = d3.scaleOrdinal(colors || d3.schemeCategory10);

    // Create a pie chart generator
    const pieGenerator = d3.pie().value(d => d.value);
    const arcGenerator = d3
      .arc()
      .innerRadius(0)
      .outerRadius(radius);

    const pieData = pieGenerator(data);

    // Select the SVG element and clear it
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    // Create the tooltip
    const tooltip = d3
      .select(svgRef.current.parentNode)
      .append('div')
      .style('position', 'absolute')
      .style('background', '#fff')
      .style('border', '1px solid #ccc')
      .style('padding', '5px')
      .style('border-radius', '4px')
      .style('box-shadow', '0px 2px 5px rgba(0, 0, 0, 0.2)')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    // Create the main chart group and move it to the center of the SVG
    const chartGroup = svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Create the pie chart segments
    chartGroup
      .selectAll('path')
      .data(pieData)
      .enter()
      .append('path')
      .attr('d', arcGenerator)
      .attr('fill', (d, i) => colorScale(i))
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .on('mouseover', d => {
        tooltip
          .style('opacity', 1)
          .html(`<strong>${d.data.name}</strong>: ${valueFormatter ? valueFormatter(d.data.value) : d.data.value}`);
      })
      .on('mousemove', function h() {
        const [mouseX, mouseY] = d3.mouse(svgRef.current);
        tooltip.style('top', `${d3.event.clientY - mouseY}px`).style('left', `${d3.event.clientX - mouseX}px`);
      })
      .on('mouseout', () => {
        tooltip.style('opacity', 0);
      });

    // Clean up tooltip on unmount
    return () => {
      tooltip.remove();
    };
  }, [data, width, height, colors, valueFormatter]);

  return <svg ref={svgRef} />;
};

export default PieChart;
