import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import GroupedBarChart from './charts/GroupedBarChart';
import LegendItem from './charts/LegendItem';
import PieChart from './charts/PieChart';
import { InventoryToolbar } from './FiltersToolbar';
import InventoryTabContext from './inventoryTabContext';
import { useInventoryProducts } from './inventoryTabUtil';
import { usePriceRecDims } from './pricingTabUtil';
import { DashboardContext } from './revenueUpliftContexts';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { compactFormatNumber, formatNumber } from '../../../util/format';
import { useGridApi } from '../../../util/gridCommonFunctions';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { MEDIUM_LIGHT_GREEN, LIGHT_BLUE, MEDIUM_COOL_GRAY } from '../../../../colors';
import { DATE_RANGE_TO_LABEL } from '../../../../utils/date-handling';
import './inventory-tab.scss';

const INVENTORY_TABS = {
  A_PRODUCT: 'A_PRODUCT',
  B_PRODUCT: 'B_PRODUCT',
  C_PRODUCT: 'C_PRODUCT',
};

const MIN_COLUMN_WIDTH = 150;

const grossPotentialFormatter = currencySymbol => {
  return params => {
    const { value } = params;
    if (value) {
      return compactFormatNumber(params.value, { formatAsCurrency: true, currencySymbol, showCents: false });
    }

    return '-';
  };
};

const priceFormatter = currencySymbol => {
  return params => {
    const { value } = params;
    if (value) {
      return compactFormatNumber(params.value, { formatAsCurrency: true, currencySymbol, showCents: true });
    }

    return '-';
  };
};

const TooltipHeader = ({ displayName, tooltipText, column, setSort }) => {
  const [currentSort, setCurrentSort] = React.useState(null);

  const handleSort = () => {
    const columnSort = column.getSort();
    const nextSort = columnSort === 'asc' ? 'desc' : columnSort === 'desc' ? null : 'asc';
    setSort(nextSort);
    setCurrentSort(nextSort);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSort();
    }
  };

  return (
    <div
      className="tooltip-header"
      role="button"
      tabIndex={0}
      onClick={handleSort}
      onKeyDown={handleKeyDown}
      style={{ cursor: 'pointer' }}
    >
      <span>{displayName}</span>
      <ProfitRoverTooltip tooltipText={tooltipText} placement="top" delay={{ show: 100, hide: 100 }}>
        <FontAwesomeIcon className="ml-1" icon={faCircleQuestion} />
      </ProfitRoverTooltip>
      {currentSort === 'asc' && <FontAwesomeIcon className="ml-1" icon={faArrowUp} />}
      {currentSort === 'desc' && <FontAwesomeIcon className="ml-1" icon={faArrowDown} />}
    </div>
  );
};

const ProductTable = ({ products, productDimCols, isActive }) => {
  const { currencySymbol, selectedRange } = React.useContext(DashboardContext);
  const containerRef = React.useRef();
  const timePeriod = DATE_RANGE_TO_LABEL[selectedRange].toLocaleLowerCase();

  const { gridApi, onGridReady } = useGridApi();
  const dimsById = usePriceRecDims();

  React.useEffect(() => {
    const gridResizeMonitor = new ResizeObserver(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    });

    if (containerRef.current) {
      gridResizeMonitor.observe(containerRef.current);
    }

    return () => gridResizeMonitor.disconnect();
  }, [gridApi, isActive]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [gridApi, isActive, products]);

  const isLoading = gridApi == null;

  return (
    <div className="eo-price-rec-table-container m-2" ref={containerRef}>
      {isLoading && (
        <div className="w-100 d-flex justify-content-center align-self-center">
          <CenteredProfitRoverSpinner />
        </div>
      )}
      <div className={classNames('ag-theme-alpine price-rec-table', { hidden: isLoading })}>
        <AgGridReact
          rowData={products}
          onGridReady={onGridReady}
          defaultColDef={{
            sortable: true,
            resizable: true,
            headerClass: 'border-right center-text',
          }}
          frameworkComponents={{
            tooltipHeader: TooltipHeader,
          }}
          groupHeaderHeight={30}
          headerHeight={30}
          maintainColumnOrder
          singleClickEdit
          suppressModelUpdateAfterUpdateTransaction
          stopEditingWhenCellsLoseFocus
        >
          {productDimCols.map(dimId => (
            <AgGridColumn
              key={dimId}
              field={dimId}
              headerName={dimsById[dimId].name}
              cellClass="border-right"
              minWidth={MIN_COLUMN_WIDTH}
            />
          ))}
          <AgGridColumn
            headerName="Price"
            cellClass="border-right center-text"
            field="price"
            valueFormatter={priceFormatter(currencySymbol)}
            minWidth={MIN_COLUMN_WIDTH}
          />
          <AgGridColumn
            headerName="Cost Per Unit"
            cellClass="border-right center-text"
            field="cost"
            valueFormatter={grossPotentialFormatter(currencySymbol)}
            minWidth={MIN_COLUMN_WIDTH}
          />
          <AgGridColumn
            headerName="Gross Profit Potential"
            cellClass="border-right center-text"
            field="grossProfitPotential"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Gross Profit Potential',
              // eslint-disable-next-line max-len
              tooltipText: `The amount of gross profit we believe you could make over ${timePeriod} if you avoid stock-outs`,
            }}
            valueFormatter={grossPotentialFormatter(currencySymbol)}
          />
          <AgGridColumn
            headerName="Revenue Potential"
            cellClass=" border-right center-text"
            field="revenuePotential"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Revenue Potential',
              tooltipText: `The amount of revenue we believe you could make over ${timePeriod} if you avoid stock-outs`,
            }}
            valueFormatter={grossPotentialFormatter(currencySymbol)}
          />
          <AgGridColumn
            headerName="Sales Qty Potential"
            cellClass="border-right center-text"
            field="salesQuantityPotential"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Sales Qty Potential',
              tooltipText: `The quantity we believe you could sell over ${timePeriod} if you avoid stock-outs`,
            }}
          />
          <AgGridColumn
            headerName="Current Inventory"
            cellClass="border-right center-text"
            field="currentInventory"
            minWidth={MIN_COLUMN_WIDTH}
          />
          <AgGridColumn
            headerName="RoverRecs Order Qty"
            cellClass=" border-right center-text"
            field="orderQuantityRec"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'RoverRecs Order Qty',
              // eslint-disable-next-line max-len
              tooltipText: `This is the additional quantity we recommend you procure to avoid stock-outs during ${timePeriod}`,
            }}
          />
          {/* <AgGridColumn
            headerName="Order Qty Plan"
            cellClass="border-right center-text"
            field="orderQuantityPlan"
            minWidth={MIN_COLUMN_WIDTH}
          /> */}
          <AgGridColumn
            headerName="Gross Profit At Risk"
            cellClass="center-text"
            field="missedGrossProfitPotential"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Gross Profit At Risk',
              // eslint-disable-next-line max-len
              tooltipText: `The amount of gross profit we believe you will miss out on during ${timePeriod} if you don’t increase your inventory`,
            }}
            valueFormatter={grossPotentialFormatter(currencySymbol)}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

const InventoryProducts = ({ isActive }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const { productDimCols, segmentAProducts, segmentBProducts, segmentCProducts } = React.useContext(
    InventoryTabContext,
  );
  const [activeTab, setActiveTab] = React.useState(INVENTORY_TABS.A_PRODUCT);

  const segments = React.useMemo(
    () => ({
      a: segmentAProducts,
      b: segmentBProducts,
      c: segmentCProducts,
    }),
    [segmentAProducts, segmentBProducts, segmentCProducts],
  );

  const colors = [MEDIUM_LIGHT_GREEN, LIGHT_BLUE, MEDIUM_COOL_GRAY];
  const barChartKeys = ['A', 'B', 'C'];

  const calculateTotals = dataKey => ({
    A: segments.a.reduce((sum, item) => sum + item[dataKey], 0),
    B: segments.b.reduce((sum, item) => sum + item[dataKey], 0),
    C: segments.c.reduce((sum, item) => sum + item[dataKey], 0),
  });

  const pieChartData = React.useMemo(
    () => [
      { name: 'A', value: segments.a.length },
      { name: 'B', value: segments.b.length },
      { name: 'C', value: segments.c.length },
    ],
    [segments],
  );

  const grossProfitData = [
    { name: 'Potential', ...calculateTotals('grossProfitPotential') },
    { name: 'At Risk', ...calculateTotals('missedGrossProfitPotential') },
  ];

  const revenueData = [
    { name: 'Potential', ...calculateTotals('revenuePotential') },
    { name: 'At Risk', ...calculateTotals('missedRevenue') },
  ];

  const salesQuantityData = [
    { name: 'Potential', ...calculateTotals('salesQuantityPotential') },
    { name: 'At Risk', ...calculateTotals('missedSalesQuantity') },
  ];

  const renderBarChart = (title, data, valueFormatter = null) => (
    <div className="bar-chart-container">
      <h4>{title}</h4>
      <GroupedBarChart
        data={data}
        keys={barChartKeys}
        colors={colors}
        width={400}
        height={250}
        valueFormatter={valueFormatter}
      />
    </div>
  );

  return (
    <div className="inventory-products">
      <div className="charts-container">
        <div className="pie-chart-container">
          <h4>Product Count</h4>
          <PieChart
            data={pieChartData}
            colors={colors}
            width={200}
            height={200}
            valueFormatter={value => formatNumber(value)}
          />
        </div>
        {renderBarChart('Gross Profit', grossProfitData, value =>
          compactFormatNumber(value, {
            formatAsCurrency: true,
            fixedDecimalDigits: 0,
            currencySymbol,
          }),
        )}
        {renderBarChart('Revenue', revenueData, value =>
          compactFormatNumber(value, {
            formatAsCurrency: true,
            fixedDecimalDigits: 0,
            currencySymbol,
          }),
        )}
        {renderBarChart('Sales Qty', salesQuantityData, value => compactFormatNumber(value, { showCents: true }))}
      </div>

      <div className="legend-container">
        <LegendItem width={20} height={20} color={MEDIUM_LIGHT_GREEN} label="A" />
        <LegendItem width={20} height={20} color={LIGHT_BLUE} label="B" />
        <LegendItem width={20} height={20} color={MEDIUM_COOL_GRAY} label="C" />
      </div>

      <Tab.Container activeKey={activeTab} onSelect={setActiveTab}>
        <Tab.Content>
          <Nav className="nav-container">
            <Nav.Item>
              <Nav.Link
                className={activeTab === INVENTORY_TABS.A_PRODUCT ? 'active-a' : 'inactive-a'}
                eventKey={INVENTORY_TABS.A_PRODUCT}
              >
                &quot;A&quot; Products
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={activeTab === INVENTORY_TABS.B_PRODUCT ? 'active-b' : 'inactive-b'}
                eventKey={INVENTORY_TABS.B_PRODUCT}
              >
                &quot;B&quot; Products
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={activeTab === INVENTORY_TABS.C_PRODUCT ? 'active-c' : 'inactive-c'}
                eventKey={INVENTORY_TABS.C_PRODUCT}
              >
                &quot;C&quot; Products
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Pane eventKey={INVENTORY_TABS.A_PRODUCT} unmountOnExit>
            <div className="tab-content">
              <ProductTable products={segments.a} isActive={isActive} productDimCols={productDimCols} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey={INVENTORY_TABS.B_PRODUCT} unmountOnExit>
            <div className="tab-content">
              <ProductTable products={segments.b} isActive={isActive} productDimCols={productDimCols} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey={INVENTORY_TABS.C_PRODUCT} unmountOnExit>
            <div className="tab-content">
              <ProductTable products={segments.c} isActive={isActive} productDimCols={productDimCols} />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

const InventoryTab = ({ isActive }) => {
  const { selectedLocation, setSelectedLocation, locationOptions } = React.useContext(InventoryTabContext);

  return (
    <div className="inventory-tab">
      <div className="my-2">
        <InventoryToolbar
          locationOptions={locationOptions}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
        />
      </div>
      <ProfitRoverCard className="inventory-card">
        <div className="inventory-card-content">
          <InventoryProducts isActive={isActive} />
        </div>
      </ProfitRoverCard>
    </div>
  );
};

const InventoryTabContextController = ({ children }) => {
  const { locationsApi } = React.useContext(DashboardContext);
  const { selectedLocation, setSelectedLocation, locationOptions } = locationsApi;
  const {
    inventoryProducts,
    productDimCols,
    segmentAProducts,
    segmentBProducts,
    segmentCProducts,
  } = useInventoryProducts(selectedLocation?.value);

  const contextValue = {
    selectedLocation,
    setSelectedLocation,
    locationOptions,
    inventoryProducts,
    productDimCols,
    segmentAProducts,
    segmentBProducts,
    segmentCProducts,
  };

  return <InventoryTabContext.Provider value={contextValue}>{children}</InventoryTabContext.Provider>;
};

const InventoryTabContainer = ({ isActive }) => {
  const { isLoadingInventory, isLoadingPricingScenarios } = React.useContext(DashboardContext);
  // const { isLoadingHoursSettings } = hoursOfOperationSettingsApi;

  if (isLoadingInventory || isLoadingPricingScenarios) {
    return (
      <div className="hours-tab h-100">
        <CenteredProfitRoverSpinner />
      </div>
    );
  }

  return (
    <InventoryTabContextController>
      <InventoryTab isActive={isActive} />
    </InventoryTabContextController>
  );
};

export default InventoryTabContainer;
