/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../routing/routesConstants';
import { addNotification } from '../../redux/notificationCenter/actions';
import { SSEEventType, useEventStreamContext } from '../../server-sent-events/eventStreamContext';

export const useListenForNotificationEvents = () => {
  const { sseEventSource } = useEventStreamContext();

  const dispatch = useDispatch();
  const saveNewNotification = React.useCallback(notification => dispatch(addNotification(notification)), [dispatch]);
  const notifications = useSelector(state => state.notificationCenter.notifications);

  React.useEffect(() => {
    if (!sseEventSource) {
      return;
    }

    const notificationArrivalListener = event => {
      const rcvData = JSON.parse(event.data);

      rcvData.id = event.lastEventId;
      saveNewNotification(rcvData);
    };

    sseEventSource.addEventListener(SSEEventType.NOTIFICATION, notificationArrivalListener);

    // eslint-disable-next-line consistent-return
    return () => {
      sseEventSource.removeEventListener(SSEEventType.NOTIFICATION, notificationArrivalListener);
    };
  }, [saveNewNotification, sseEventSource]);

  return { notifications };
};

export const useNavigateToDataCenter = () => {
  const history = useHistory();

  return () => history.push(PATHS.dataSources);
};

export const useNavigateToSubscriptionManagement = () => {
  const history = useHistory();

  return () => history.push(PATHS.subscription);
};

export const useNavigateToMapping = dimensionId => {
  const history = useHistory();

  return () => history.push(PATHS.dimensionValueMapping, { dimensionId });
};
