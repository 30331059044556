import React from 'react';
import * as d3 from 'd3';

/**
 * GroupedBarChart component renders a horizontal bar chart using D3.js.
 *
 * @param {Object[]} data - The data to be displayed in the chart.
 * @param {string[]} keys - The keys to be used for the bars in the chart.
 * @param {number} [width=500] - The width of the chart.
 * @param {number} [height=300] - The height of the chart.
 * @param {Object} [margin={ top: 20, right: 20, bottom: 30, left: 100 }] - The margins around the chart.
 * @param {string[]} [colors] - The colors to be used for the bars.
 * @param {Function} [valueFormatter=d => d] - The function to format the values on the X axis.
 *
 * @throws {Error} Throws an error if data or keys are not provided.
 *
 * @example
 * <GroupedBarChart
 *   data={[{ name: 'A', value1: 10, value2: 20 }, { name: 'B', value1: 30, value2: 40 }]}
 *   keys={['value1', 'value2']}
 *   width={600}
 *   height={400}
 *   margin={{ top: 20, right: 20, bottom: 30, left: 100 }}
 *   colors={['#ff0000', '#00ff00']}
 *   valueFormatter={d => `$${d}`}
 * />
 */
const GroupedBarChart = ({
  data = [],
  keys = [],
  width = 500,
  height = 300,
  margin = { top: 20, right: 30, bottom: 30, left: 100 },
  colors,
  valueFormatter = d => d, // Default formatter that returns the value unchanged
}) => {
  const svgRef = React.useRef();
  const ticks = 3;

  React.useLayoutEffect(() => {
    if (!data.length || !keys.length) {
      throw new Error('Developer error: Data and keys props are required to render the chart');
    }

    // Margin configuration
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => Math.max(...keys.map(key => d[key])))])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleBand()
      .domain(data.map(d => d.name))
      .range([0, innerHeight])
      .padding(0.2);

    // Color configuration
    const colorScale = d3
      .scaleOrdinal()
      .domain(keys)
      .range(colors || d3.schemeCategory10);

    // Clear SVG and set dimensions
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();
    const chartGroup = svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Add vertical grid lines on the X axis
    chartGroup
      .append('g')
      .attr('class', 'grid-lines')
      .selectAll('line')
      .data(xScale.ticks(ticks))
      .enter()
      .append('line')
      .attr('x1', d => xScale(d))
      .attr('x2', d => xScale(d))
      .attr('y1', 0)
      .attr('y2', innerHeight)
      .attr('stroke', '#ddd')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '3 3');

    // Add X axis with value formatting
    chartGroup
      .append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(ticks)
          .tickFormat(valueFormatter),
      )
      .selectAll('text')
      .style('font-size', '12px');

    // Add Y axis
    chartGroup
      .append('g')
      .call(d3.axisLeft(yScale))
      .selectAll('text')
      .style('font-size', '12px');

    // Add tooltip container
    const tooltip = d3
      .select(svgRef.current.parentNode) // Parent node of the SVG
      .append('div')
      .style('position', 'absolute')
      .style('background', '#fff')
      .style('border', '1px solid #ccc')
      .style('padding', '5px')
      .style('border-radius', '4px')
      .style('box-shadow', '0px 2px 5px rgba(0, 0, 0, 0.2)')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    // Create bars for each key
    keys.forEach((key, i) => {
      chartGroup
        .selectAll(`.bar-${key}`)
        .data(data)
        .enter()
        .append('rect')
        .attr('class', `bar-${key}`)
        .attr('x', 0)
        .attr('y', d => yScale(d.name) + i * (yScale.bandwidth() / keys.length))
        .attr('width', d => xScale(d[key]))
        .attr('height', yScale.bandwidth() / keys.length)
        .attr('fill', colorScale(key))
        .on('mouseover', d => {
          tooltip
            .style('opacity', 1)
            .html(`<strong>${key}:</strong> ${valueFormatter ? valueFormatter(d[key]) : d[key]}`);
        })
        .on('mousemove', function handleMouseMove() {
          const rect = d3.select(this);
          const rectY = parseFloat(rect.attr('y'));
          const rectHeight = parseFloat(rect.attr('height'));

          tooltip.style('top', `${rectY + margin.top + rectHeight / 2}px`).style('left', `${d3.event.clientX + 10}px`);
        })
        .on('mouseout', () => {
          tooltip.style('opacity', 0);
        });
    });

    // Clean up tooltip on unmount
    return () => {
      tooltip.remove();
    };
  }, [data, keys, width, height, margin, colors, valueFormatter]);

  return <svg ref={svgRef} />;
};

export default GroupedBarChart;
