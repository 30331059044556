import React from 'react';
import { PaymentFailedError } from './checkoutErrors';
import { ChargedPaymentSuccess } from './PaymentSuccessDetails';
import { endTrialEarly, resume } from '../../../data-access/mutation/subscriptions';

const endTrial = async (subscriptionId, currentStripePriceId, trialHasExpired) => {
  let response;

  if (trialHasExpired) {
    response = await resume(subscriptionId, currentStripePriceId);
  } else {
    response = await endTrialEarly(subscriptionId, currentStripePriceId);
  }

  const { payment_details: paymentDetails } = response?.data ?? {};
  const { unpaid_invoice_id: unpaidInvoiceId, paid_invoice: paidInvoice } = paymentDetails;

  return {
    unpaidInvoiceId,
    paidInvoice,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const useEndTrial = (subscriptionId, currentStripePriceId, trialHasExpired) => {
  const [showPaymentFailed, setShowPaymentFailed] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [paymentCompletionDetails, setPaymentCompletionDetails] = React.useState();

  const onConfirmChanges = async () => {
    setIsSubmitting(true);

    try {
      const { unpaidInvoiceId, paidInvoice } = await endTrial(subscriptionId, currentStripePriceId, trialHasExpired);

      const paymentChargeSucceeded = unpaidInvoiceId == null;

      if (paymentChargeSucceeded) {
        return setPaymentCompletionDetails(
          new ChargedPaymentSuccess({ paidInvoice, paymentEmailAddress: paidInvoice?.customer_email }),
        );
      }

      setShowPaymentFailed(true);
      return setSubmissionError(new PaymentFailedError('Payment attempt unsuccessful', unpaidInvoiceId));
    } catch (error) {
      return setSubmissionError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    onConfirmChanges,
    isSubmitting,
    paymentCompletionDetails,
    setPaymentCompletionDetails,
    showPaymentFailed,
    setShowPaymentFailed,
    submissionError,
  };
};
