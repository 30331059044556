import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_INVENTORY_SETTINGS = `${KA_API_URL}/config/inventory_settings`;

/**
 * Requests
 */

const fetchInventorySettings = async () => axios.get(GET_INVENTORY_SETTINGS);

/**
 * Hooks
 */

const INVENTORY_SETTINGS_QUERY_KEY_BASE = 'inventorySettings';

export const useInventorySettings = queryConfig =>
  useQuery(
    [INVENTORY_SETTINGS_QUERY_KEY_BASE],
    async () => {
      const response = await fetchInventorySettings();

      return response?.data ?? [];
    },
    queryConfig,
  );
