export const INVENTORY_SETTINGS_KEYS = {
  TOP_PRODUCTS_PERCENTAGE: 'top_products_percentage',
  BOTTOM_PRODUCTS_PERCENTAGE: 'bottom_products_percentage',
};

export const BASE_RECOMMENDATIONS = {
  FORECAST_AVG: 'forecast_avg',
  LAST_4_WEEKS_AVG: 'last_4_weeks_avg',
  STLY_AVG: 'stly_avg',
};

export const BASE_RECOMMENDATIONS_LABELS = {
  [BASE_RECOMMENDATIONS.FORECAST_AVG]: 'Forecasted Revenue',
  [BASE_RECOMMENDATIONS.LAST_4_WEEKS_AVG]: 'Last 4 Weeks Avg Revenue',
  [BASE_RECOMMENDATIONS.STLY_AVG]: 'STLY Avg Revenue',
};

export const DEFAULT_INVENTORY_SETTINGS = {
  [INVENTORY_SETTINGS_KEYS.TOP_PRODUCTS_PERCENTAGE]: 80,
  [INVENTORY_SETTINGS_KEYS.BOTTOM_PRODUCTS_PERCENTAGE]: 5,
};
