import _ from 'lodash';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import CustomerSelect from './CustomerSelect';
import impersonateUser from './impersonation';
import UserSelect from './UserSelect';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import Header from '../header/header';
import { PageHeader, PromptText } from '../forms/GenericFormComponents';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import { KaizenProductStatus } from '../generic/subscriptions/SubscriptionConstants';
import { Can } from '../../casl/abilityContext';
import { Action, Subject } from '../../casl/caslConstants';
import { useSetupCompletion } from '../../data-access/query/guidedSetup';
import { useCustomerSubscriptions } from '../../data-access/query/subscriptions';
import { useUsers } from '../../data-access/query/users';
import './impersonate-user-page.scss';

const mapUserToOption = user => ({ label: user.email_address, value: user.user_id });

const ImpersonateUserPage = () => {
  const history = useHistory();
  const [customer, setCustomer] = React.useState();
  const [user, setUser] = React.useState();
  const [message, setMessage] = React.useState('');

  const selectedCustomerId = customer?.value;
  const { data: setupCompletion = [], isLoading: isLoadingSetupCompletion } = useSetupCompletion();
  const { data: allUsers = [], isLoading: isLoadingUsers } = useUsers(selectedCustomerId, {
    enabled: selectedCustomerId != null,
  });
  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } = useCustomerSubscriptions(selectedCustomerId, {
    enabled: selectedCustomerId != null,
  });
  const isLoading = isLoadingUsers || isLoadingSubscriptions || isLoadingSetupCompletion;
  const isAnySubscriptionActive = _.some(subscriptions, s => s.kaizen_product_status === KaizenProductStatus.ACTIVE);
  const isAnySubscriptionTrialing = _.some(
    subscriptions,
    s => s.kaizen_product_status === KaizenProductStatus.TRIALING,
  );
  const userOptions = React.useMemo(() => _.sortBy(allUsers.map(mapUserToOption), option => option.label), [allUsers]);

  const onClickImpersonate = async () => {
    try {
      await impersonateUser(user?.label);

      setMessage('');

      history.push({ pathname: '/login-redirect' });
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <HeaderAwarePage className="impersonate-user">
      <Header />

      <div className="p-3 px-5">
        <PageHeader className="mb-3">Impersonate User</PageHeader>

        <div className="select-spacing">
          <PromptText>Please select a customer:</PromptText>
          <CustomerSelect
            className="select-customer"
            onChange={e => {
              if (e?.value !== customer?.value) {
                setCustomer(e);
                setUser(null);
              }
            }}
            value={customer}
          />
          {!isLoading && selectedCustomerId != null && isAnySubscriptionTrialing && (
            <>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color="#f6be00"
                style={{ marginTop: 10, marginRight: 10, fontSize: 20 }}
              />
              <span>Trial subscription</span>
            </>
          )}
          {!isLoading && selectedCustomerId != null && !isAnySubscriptionTrialing && !isAnySubscriptionActive && (
            <>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color="#f6be00"
                style={{ marginTop: 10, marginRight: 10, fontSize: 20 }}
              />
              <span>No Active subscriptions</span>
            </>
          )}
          {!isLoading &&
            selectedCustomerId != null &&
            setupCompletion.find(x => x.customer_id === selectedCustomerId && x.is_complete) == null && (
              <>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  color="#f6be00"
                  style={{ marginLeft: 10, marginTop: 10, marginRight: 10, fontSize: 20 }}
                />
                <span>Incomplete Setup</span>
              </>
            )}
        </div>

        <div className="select-spacing">
          <PromptText>Please select a user:</PromptText>
          <UserSelect
            className="select-user"
            isLoading={isLoading}
            options={isLoading ? [] : userOptions}
            onChange={e => setUser(e)}
            value={user}
          />
        </div>

        <div>
          <ProfitRoverPrimaryButton onClick={onClickImpersonate}>Impersonate</ProfitRoverPrimaryButton>
        </div>
        <div className="error-message">{message}</div>
      </div>
    </HeaderAwarePage>
  );
};

const PageAccessCheck = props => (
  <Can I={Action.IMPERSONATE} a={Subject.USER} passThrough>
    {allowed => (allowed ? <ImpersonateUserPage {...props} /> : <Redirect to="/welcome" />)}
  </Can>
);

export default PageAccessCheck;
